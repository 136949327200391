import React from 'react';

const PoliciesAndConditions = () => (
  <div className="agreement-container">
    <h2 className="agreement-container__header">Terms and Conditions</h2>
    <div className="agreement-container-content">
      <div className="agreement-item">
        <p><strong>Livingston Research</strong> conditionally offers to provide freelance opportunities as an
          independent contractor and shall assign Freelancer to one or more Platform Users,
          (“Platform User“) for services, to perform such duties and for such hours of work as may
          be assigned to Freelancer during the term of service (the “Gig”) for which Freelancer
          Provider and the Freelancer (together, the “Parties”) agrees as follows:</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">1. The Platform</h3>
        <p>Livingston Research (Freelance Provider) is represented by ETT
          Outsource Limited, with its principal office located at 2 Iliensko shose office 9, 1220
          Sofia, Bulgaria, is in the business of providing qualified freelancers for the Livingston
          Research platform (“LR Platform”) which is a digital marketplace for users seeking
          freelancers to perform certain listed services for specifically defined engagements
          (“Gigs”). The Freelancer is engaged by the Platform User as an independent
          contractor and receives its compensation from the Freelancer Provider.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">2. Benefits</h3>
        <p>As an independent contractor, Freelancer is not eligible to participate in
          any benefit programs, including programs such as medical, dental, vision, disability
          and retirement provided by Freelancer Provider.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">3. Gig(s)</h3>
        <p>Freelancer understands and acknowledges that each Gig is subject to final
          approval by a Platform User and that Freelancer shall not be entitled to any fees
          unless actually engaged on the LR Platform to work a specific Gig for a Platform
          User pursuant to this agreement (“Agreement”). Freelancer understands that this
          Agreement will not be effective until Freelancer actually works on the agreed upon
          Gig. Freelancer acknowledges and understands that Freelancer’s engagement with
          both Freelance Provider and the Platform User is, in both cases as an independent
          contractor, with no certain term being offered or promised and that Freelancer
          Provider or Platform User may terminate a Gig or otherwise cancel a Gig, at any
          time with or without cause, provided that Freelancer receives payment for services
          rendered prior to termination. Except as expressly authorized by LR Platform or
          these Terms, Freelancer agrees not to modify, copy, frame, scrape, rent, lease,
          loan, sell, distribute or create derivative works based on the Gigs or the Gigs’
          Content, in whole or in part during Gig performance.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">4. Notification of Completion of Gig</h3>
        <p>Freelancer agrees that upon completion of the
          Gig with Platform User, Freelancer will notify Freelancer Provider that Freelancer
          has finished the Gig. Freelancer understands that failure to contact Freelancer
          Provider upon completion of a Gig may affect Freelancer’s ability to receive its fees.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">5. Scope of Gig with Freelancer Provider</h3>
        <p>Freelancer understands that
          Freelancer’s Gig begins when Freelancer first begins work for a Platform User on the Gig, and ends when the Gig ends. Following the end of the Gig, while Freelancer
          may remain eligible for future Gig(s) with other Platform Users, Freelancer will not be
          employed with Freelancer Provider unless and until Freelancer is re-hired and
          assigned to another Platform User. Freelancer understands that Gigs are conducted
          on an on-demand basis and Gigs may be sporadic, intermittent, unpredictable and
          irregular. Freelancer Provider does not guarantee certain fixed Gigs at any point.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">6. Intellectual Property Rights</h3>
        <p>All text, graphics, editorial content, data, formatting,
          graphs, designs, HTML, look and feel, photographs, music, sounds, images,
          software, videos, typefaces and other content, including Livingston Research
          designs, trademarks, and logos (collectively “Proprietary Material”) that Platform
          Users see or read through the LR Platform is owned by Livingston Research,
          including Freelancer’s Generated Content, while performing a Gig.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">7. Compensation</h3>
        <p>Freelancer will be paid directly by the Freelancer Provider for all
          the Gigs worked after taking Freelancer Provider and LR Platform fees. Any fee
          amounts posted for a Gig, are the gross rates and the amount Freelancer will
          receive per hour will be after identified deductions. For any questions related to the
          payment process, please email <a href="mailto:support@liv-research.com">support@liv-research.com</a></p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">8. Freelancer Representations and Warranties</h3>
        <p>Freelancer shall use best efforts
          and the highest degree of professionalism and skill to perform Gig(s). Freelancer
          shall not accept any proposal for Gig(s) for which Freelancer is not qualified and are
          unable to perform competently, seasonably and promptly. By accepting a proposal
          for a Gig, Freelancer represents and warrants that Freelancer is sufficiently qualified
          to perform the Gig(s), Freelancer has not been convicted of or plead guilty or nolo
          contendere to a crime that constitutes a felony (or state law equivalent) or any crime
          that constitutes a misdemeanor involving moral turpitude. Freelancer will comply
          with all rules, regulations and policies of the Platform User and Freelancer Provider,
          Freelancer will not misappropriate or otherwise use in contravention of any
          agreement or applicable law any confidential or proprietary information of the
          Platform User or Freelancer Provider, and Freelancer will comply with all applicable
          law. Freelancer may not use, or encourage, promote, facilitate, or instruct, induce, or
          otherwise influence or cause others: (1) to use the Platform or Platform Services for
          any activities that violate any law, statute, ordinance or regulation; for any other
          illegal or fraudulent purpose or any purpose that is harmful to others, or (2) to
          transmit, store, display, distribute or otherwise make available content that is illegal,
          fraudulent or harmful to others.</p>
        <p>The following are examples of uses that are prohibited on the Platform or when using
          the Platform Site Services:</p>
        <ul className="agreement-item__list">
          <li>Seeking, offering, promoting, supporting, or endorsing services, content, or
            activities that:
            are defamatory, illegal, violent, profane, vulgar, threatening, unlawfully
            discriminatory, illegal, pornographic, obscene, or sexually explicit in nature;</li>
          <li>Would violate the intellectual property rights, such as and including copyrights, of
            another person, entity, service, product, or website;</li>
          <li>Fraudulent or misleading uses or content, including: fraudulently billing or
            attempting to fraudulently bill any Platform User; misrepresenting your
            experience, skills, or information, including by representing another person’s
            profile, or parts of another person’s profile, as your own; allowing another person
            to use your account, which is misleading to other Users; making or demanding
            bribes or demanding other payments without the intention of or without actually
            providing services in exchange for the payment.</li>
        </ul>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">9. Amendment Regarding Sanctioned Countries</h3>
        <p>Freelancer further represents
          and warrants that Freelancer is not a citizen, resident, or otherwise located in a
          country or territory that is subject to comprehensive international sanctions or
          embargoes, including but not limited to sanctions imposed by the United Nations, the
          European Union, or the United States Department of the Treasury's Office of Foreign
          Assets Control (OFAC). Freelancer specifically affirms that they are not currently
          located in, or acting on behalf of, any of the following sanctioned countries or
          regions: Afghanistan, Balkans, Belarus, Burundi, Central African Republic, Chad,
          Congo, Cuba, Democratic Republic of the Congo, Eritrea, Ethiopia, Hong Kong, Iran,
          Iraq, Lebanon, Libya, Mali, Myanmar, Nicaragua, North Korea, Republic of South
          Sudan, Russia, Somalia, Sudan, Syria, Ukraine regions: Crimea, Donetsk, Luhansk,
          Kherson, Zaporizhzhia, Venezuela, Yemen.
          Freelancer agrees to immediately notify Freelancer Provider Livingston Research in
          writing if there is any change to this representation and acknowledges that any
          misrepresentation or breach of this warranty may result in the termination of
          Freelancer’s engagement and/or legal action in accordance with applicable
          sanctions laws and regulations. Freelancer shall indemnify and hold Freelancer
          Provider harmless from any liability arising from false declarations or a breach of this
          warranty.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">10. Confidentiality</h3>
        <p>Freelancer agrees not to disclose to anyone, either during or after
          Freelancer’s business relationship with Freelancer Provider or during or after any
          Platform User Gig, any confidential or proprietary information of any kind obtained by
          Freelancer as a result of Freelancer’s performance of a Gig without the written
          consent of executive officers of both the Platform User and Freelancer Provider, and
          if in the event Freelancer is contacted by anyone outside the Freelancer Provider
          including the media regarding Freelancer’s Gig with Freelancer Provider or any other matter related to Freelancer Provider. To the extent a Platform User provides
          Confidential Information to the Freelancer, the recipient will protect the secrecy of
          the discloser’s Confidential Information with the same degree of care as it uses to
          protect its own Confidential Information.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">11. Freelancer’s Profile on the LR Platform</h3>
        <p>Freelancer acknowledges that Freelancer Provider may use Freelancer’s profile to market to Platform Users to attract new business opportunities and that Platform Users may rate the Freelancer’s performance provided in the Gig(s) which may be displayed to the public on the LR Platform. LR Platform doesn’t control or monitor how Platform Users rate the Freelancer.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">12. Entire Agreement</h3>
        <p>Except as expressly set forth herein, this Agreement and the terms and conditions set forth in any Gig documentation, represent the entire agreement of the parties with respect to the subject matter hereof, and any and all agreements entered into prior hereto with respect to the subject matter hereof are revoked and superseded by this Agreement. No representations, promises, warranties, inducements or oral agreements have been made by any of the parties except as expressly set forth herein or in other contemporaneous written agreements specifically identified herein.  Any attempt at oral modification if this Agreement shall be void and of no effect.</p>
        <p>Notwithstanding the foregoing, Freelancer Provider may revise and update these online terms of use from time to time in Freelancer Provider’ sole discretion. All changes are effective immediately when Freelancer Provider post them, and apply to all access to and use of the Platform thereafter.   Freelancer’s continued use of the Platform following the posting of revised terms of use means that Freelancer accepts and agrees to the changes. Freelancer is expected to check this page from time-to-time, so Freelancer is aware of any changes, as they are binding on Freelancer.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">13. Severability</h3>
        <p>In the event any provision or clause of this Agreement is found to be unenforceable by a court of competent jurisdiction, all remaining provisions shall remain in full force and effect.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">14. Review of Terms</h3>
        <p>Freelancer acknowledges that he/she has fully read and understands this agreement before signing.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">15. Identification and Background Check</h3>
        <p>To the extent requested by the Freelancer Provider or the Platform User, each of which reserve their right to the maximum extent permissible by law, Freelancer agrees to provide, and will consent to the release of, any information about Freelancer for the purpose of a background check in all instances in compliance with applicable law. Freelancer will provide truthful, accurate and complete information, and will provide prompt written notice to the Platform User and Freelancer Provider upon any change thereto.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">16. Limitation of Liability</h3>
        <p>To the extent permitted by law, Freelancer, on Freelancer’s own behalf and on behalf of anyone claiming by or through Freelancer, waive any and all rights Freelancer have, or may have, to claim or assert a claim, suit, action or demand of any kind, nature or description, including without limitation, claims, suits, actions or demands for personal injury or death whether arising in tort, contract or otherwise, against Freelancer Provider and Platform User or their respective customers, agents, officers, directors, or employees, resulting from or arising directly or indirectly out of Freelancer’s services performed during Gig on or through the LR Platform, except as to claims Freelancer assigns to Freelancer Provider under the Agreement.  IN NO EVENT SHALL EITHER FREELANCER PROVIDER OR PLATFORM USER BE LIABLE TO FREELANCER FOR INDIRECT, CONSEQUENTIAL, PUNITIVE OR SPECIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, LOST PROFITS (DIRECT OR INDIRECT), LOST REVENUES (DIRECT OR INDIRECT), LOSS OF DATA, LOSS OF GOODWILL, OR PUNITIVE DAMAGES, EVEN IF MADE AWARE OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMISSIBLE BY APPLICABLE LAW, FREELANCER PROVIDER’ AGGREGATE AND CUMULATIVE LIABILITY IN CONNECTION BY REASON OF YOUR USE OF THE PLATFORM, WHETHER IN CONTRACT, TORT, STATUTE, COMMON LAW OR OTHERWISE, WILL NOT EXCEED THE AGGREGATE AMOUNT OF ACTUAL PAYMENTS MADE TO FREELANCER BY THE COMPANY DURING THE SIX-MONTH PERIOD PRIOR TO THE OCCURRENCE (NOT THE DISCOVERY) OF THE FIRST CLAIM GIVING RISE TO LIABILITY.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">17. Indemnification</h3>
        <p>Freelancer agree to indemnify and hold harmless Freelancer Provider from any and all liability, loss, damages, attorney’s fees, or expenses which may be caused by Freelancer’s negligence, willful actions, omissions or failure to perform the Gig and/or Freelancer’s obligations under the terms of this Agreement.</p>
      </div>
      <div className="agreement-item">
        <h3 className="agreement-item__header">18. Governing Law; Consent to Jurisdiction and Venue; WAIVER OF JURY TRIAL.</h3>
        <p>The law of Bulgaria shall exclusively govern (i) all claims, disputes and matters related to or arising from this Agreement (including any tort or non-contractual claims) and (ii) any questions concerning the construction, interpretation, validity and enforceability of this Agreement, and the performance by the parties hereto of the obligations imposed on them by this Agreement, in each case without giving effect to any choice of law or conflict of law rules or provisions that would cause the application of the law of any jurisdiction other than Bulgaria EACH PARTY TO THIS AGREEMENT HEREBY IRREVOCABLY WAIVES ALL RIGHTS TO TRIAL BY JURY IN ANY ACTION, SUIT OR PROCEEDING BROUGHT TO RESOLVE ANY CLAIMS OR DISPUTE BETWEEN THE PARTIES HERETO (WHETHER ARISING IN CONTRACT, TORT OR OTHERWISE) ARISING OUT OF, CONNECTED WITH, RELATED OR INCIDENTAL TO THIS AGREEMENT, THE TRANSACTIONS CONTEMPLATED HEREBY AND/OR THE RELATIONSHIPS ESTABLISHED AMONG THE PARTIES HERETO UNDER THIS AGREEMENT. Each of the parties hereto submits to the exclusive jurisdiction of the court of Bulgaria in any action or proceeding arising out of or relating to this Agreement and agrees that all claims in respect of any such action or proceeding shall be heard and determined only in Bulgarian Courts. Nothing in this Section 16, however, shall affect the right of any party hereto to serve legal process in any other manner permitted by law or at equity. Each party hereto agrees that a final judgment in any proceeding so brought shall be conclusive and may be enforced by suit on the judgment or in any other manner provided by law or at equity.</p>
      </div>
      <p>BY ACKNOWLEDGING THE TERMS OF SERVICE AND/OR USING THE LIVINGSTON RESEARCH PLATFORM, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE, WITHOUT LIMITATION OR QUALIFICATION, TO BE BOUND BY THIS AND YOU ACCEPT ALL OF ITS TERMS.</p>
    </div>
  </div>
);
export default PoliciesAndConditions;
